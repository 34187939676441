import {Injectable} from '@angular/core';
import {DocumentViewer} from '@ionic-native/document-viewer/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {LoadingController, Platform, ToastController} from '@ionic/angular';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {environment} from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
@Injectable({
  providedIn: 'root'
})
export class FileOpenerService {

  constructor(private transfer: FileTransfer,
              private file: File,
              public loadingController: LoadingController,
              protected toastHelper: ToastController,
              protected platform: Platform,
              protected fileOpener: FileOpener,
              private document: DocumentViewer,
              public authService: AuthenticationService) {}

  /**
   * To open files
   * @param fileName
   * @param filetype
   * @param url
   */
  async openAttachmentInBrowser(fileName, filetype, url) {
    const l = await this.toastHelper.create({
      message: 'Loading...',
      duration: 10000,
    });
    await l.present();
    const downloadFolderName = environment.appName; // 'oas36ty/';
    const file = fileName;
    let path = null;
    //console.log('platform:', this.platform.is('android'));
    
    if (this.platform.is('ios')) {
      //console.log('ios url:', url);
      path = this.file.documentsDirectory;
    } else if (this.platform.is('android')) {
      //console.log('android url:', url);
     // path = this.file.externalRootDirectory + '/';
        path = this.file.dataDirectory;
      //path = this.file.externalRootDirectory;
    } else {
      //console.log('url:', url);
      window.open(url);
    }
  //   let options = {
  //     headers: {
  //       'Authorization': `Bearer ${this.authService.getToken()}`,
  //       'Access-Control-Allow-Headers': 'Content-Type',
  //       'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
  //       'Access-Control-Allow-Origin': '*'

  //     }
  // }
    console.log('path:', this.file.dataDirectory);
  
    const transfer = this.transfer.create();
    transfer.download(url, path + file)
        .then(response => {
          const imagePath = response.toURL();
          if (this.platform.is('ios')) {
            l.dismiss();
            this.document.viewDocument(imagePath, filetype, {});
          } else {
            this.fileOpener.open(imagePath, filetype)
                .then(() => {
                  l.dismiss();
                })
                .catch(e => {
                  console.log('open error', e);
                  
                  this.toastHelper.create({
                    message: 'Invalid file type',
                    duration: 2000,
                  }).then(t => t.present());
                  l.dismiss();
                });
          }
        })
        .catch(err => {
          console.log('download error:', err);
          
          this.toastHelper.create({
            message: 'Invalid file type',
            duration: 2000,
          }).then(t => t.present());
          l.dismiss();
        });
    await l.dismiss();
  }

  /**
   * To open files
   * @param fileName
   * @param filetype
   * @param url
   */
   async storeFileToDevice(fileName, url) {
    const l = await this.toastHelper.create({
      message: 'Loading...',
      duration: 2000,
    });
    await l.present();
    const downloadFolderName = environment.appName; // 'oas36ty/';
    const filename = fileName;
    let path = null;
    //console.log('platform:', this.platform.is('android'));
    
    if (this.platform.is('ios')) {
      //console.log('ios url:', url);
      path = this.file.documentsDirectory;
    } else if (this.platform.is('android')) {
      //console.log('android url:', url);
      path = this.file.dataDirectory;
    } else {
      //console.log('url:', url);
      window.open(url);
    }
    
    // this.file.writeFile(path + '/' + downloadFolderName, fileName, url, { replace: true }).then((fileEntry) => {

    //   console.log('File created!');
    //   l.dismiss();
    //   //Open with File Opener plugin
    //   this.fileOpener.open(fileEntry.toURL(), filetype)
    //     .then(() => console.log('File is opened'))
    //     .catch(err => console.error('Error openening file: ' + err));
    // })
    //   .catch((err) => {
    //     console.error('Error creating file: ' + err);
    //     this.toastHelper.create({
    //       message: 'Invalid file type',
    //       duration: 2000,
    //     }).then(t => t.present());
    //     l.dismiss();
    //     //throw err;  //Rethrow - will be caught by caller
    //   });
    const transfer = this.transfer.create();
    transfer.download(url, path + downloadFolderName)
        .then(response => {
          l.dismiss();
          const imagePath = response.toURL();
          return response;
          // if (this.platform.is('ios')) {
          //   l.dismiss();
          //   this.document.viewDocument(imagePath, filetype, {});
          // } else {
          //   this.fileOpener.open(imagePath, filetype)
          //       .then(() => {
          //         l.dismiss();
          //       })
          //       .catch(e => {
          //         this.toastHelper.create({
          //           message: 'Invalid file type',
          //           duration: 2000,
          //         }).then(t => t.present());
          //         l.dismiss();
          //       });
          // }
        })
        .catch(err => {
          this.toastHelper.create({
            message: 'Invalid file type',
            duration: 2000,
          }).then(t => t.present());
          l.dismiss();
        });
    await l.dismiss();
    return '';
  }
}
