import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { EventService } from './event.service';
import { Observable, fromEvent, merge, of} from 'rxjs';
import { map } from 'rxjs/operators';

export enum ConnectionStatusEnum {
  Online,
  Offline,
}
@Injectable({
  providedIn: 'root'
})
export class NetworkProviderService {
  previousStatus;
  public appIsOnline$: Observable<boolean>;
  constructor(
    public network: Network,
    public eventCtrl: EventService
  ) { 
    this.previousStatus = ConnectionStatusEnum.Online;
    this.initConnectivityMonitoring();
  }
  public initializeNetworkEvents(): void {
    console.log('navigator:', navigator);
    
    if (navigator.onLine){
      if (this.previousStatus === ConnectionStatusEnum.Online) {
        this.eventCtrl.publish('network:offline');
      }
      this.previousStatus = ConnectionStatusEnum.Offline;
    }
    else{
      if (this.previousStatus === ConnectionStatusEnum.Offline) {
        this.eventCtrl.publish('network:online');
      }
      this.previousStatus = ConnectionStatusEnum.Online;
    }
    // if (this.network.type === 'none') {
    //   this.eventCtrl.publish('network:offline');
    // }
    // this.network.onDisconnect().subscribe(() => {
    //   console.log('offline');
    //   if (this.previousStatus === ConnectionStatusEnum.Online) {
    //     this.eventCtrl.publish('network:offline');
    //   }
    //   this.previousStatus = ConnectionStatusEnum.Offline;
    // });
    // this.network.onConnect().subscribe(() => {
    //   console.log('online');
    //   if (this.previousStatus === ConnectionStatusEnum.Offline) {
    //     this.eventCtrl.publish('network:online');
    //   }
    //   this.previousStatus = ConnectionStatusEnum.Online;
    // });
  }

  private initConnectivityMonitoring() {

    if (!window || !navigator || !('onLine' in navigator)) return;

    this.appIsOnline$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(map(() => navigator.onLine))

  }
}
