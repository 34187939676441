import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {assign} from 'lodash';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  protected baseUrl: string;
  protected defaultHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
  };

  constructor(
      protected http: HttpClient,
  ) {
    this.baseUrl = environment.apiBaseUrl;
  }

  protected path(path: string) {
    return this.baseUrl + path;
  }

  protected extendReqOptionsWithGetParams(urlParams: object) {
    let params = new HttpParams();

    for (const k in urlParams) {
      if (urlParams.hasOwnProperty(k)) {
        params = params.set(k, urlParams[k]);
      }
    }
    return {params};
  }

  protected extendReqOptions(requestOptions: object) {
    return assign(
        {headers: this.defaultHeaders},
        requestOptions,
    );
  }

  get(endpoint: string, params?: object, requestOptions?: object): Observable<ArrayBuffer> {
    const options = assign(
        this.extendReqOptionsWithGetParams(params),
        this.extendReqOptions(requestOptions),
    );
    return this.http.get(this.path(endpoint), options);
  }

  // tslint:disable-next-line:ban-types
  post(endpoint: string, body: object, requestOptions?: object): Observable<Object> {
    return this.http.post(this.path(endpoint), body, this.extendReqOptions(requestOptions));
  }

  // tslint:disable-next-line:ban-types
  put(endpoint: string, body: object, requestOptions?: object): Observable<Object> {
    return this.http.put(this.path(endpoint), body, this.extendReqOptions(requestOptions));
  }

  // tslint:disable-next-line:ban-types
  patch(endpoint: string, body: object, requestOptions?: object): Observable<Object> {
    return this.http.patch(this.path(endpoint), body, this.extendReqOptions(requestOptions));
  }

  // tslint:disable-next-line:ban-types
  delete(endpoint: string, requestOptions?: object): Observable<Object> {
    return this.http.delete(this.path(endpoint), this.extendReqOptions(requestOptions));
  }
}
