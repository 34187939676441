import { Component, OnInit } from '@angular/core';
import {LoadingController, MenuController, ModalController, NavParams, ToastController} from '@ionic/angular';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {finalize, first} from 'rxjs/operators';
import {AuthenticationService} from '../../services/authentication.service';
import {Storage} from '@ionic/storage';

@Component({
  selector: 'app-logout-alert',
  templateUrl: './logout-alert.page.html',
  styleUrls: ['./logout-alert.page.scss'],
})
export class LogoutAlertPage implements OnInit {
  enrollmentId;
  baseUrl;
  /**
   * successfully logot & clear storage
   */
  onLoginSuccess = (data) => {
    console.log(data);
    this.storage.remove('user');
    this.storage.remove('employee');
    this.storage.remove('client');
    this.storage.remove('access_token');
    this.storage.remove('client_logo');
    this.storage.remove('gmc_status');
    this.storage.remove('gpa_status');
    this.storage.remove('gtl_status');
    this.storage.remove('policyType');
    this.storage.remove('gmc_prospect_id');
    this.storage.remove('gpa_prospect_id');
    this.storage.remove('gtl_prospect_id');
    this.storage.remove('enrollments');
    this.storage.remove('tpa_ids');
    this.storage.remove('policy_number');
    this.storage.remove('userName');
    this.storage.remove('nomineeEdit');
    this.menu.enable(false);
    // this.storage.clear();
    this.modalCtrl.dismiss();
    this.router.navigateByUrl('login');
  }
  onLoginError = (error) => {
    this.storage.remove('user');
    this.storage.remove('employee');
    this.storage.remove('client');
    this.storage.remove('access_token');
    this.storage.remove('client_logo');
    this.storage.remove('gmc_status');
    this.storage.remove('gpa_status');
    this.storage.remove('gtl_status');
    this.storage.remove('policyType');
    this.storage.remove('gmc_prospect_id');
    this.storage.remove('gpa_prospect_id');
    this.storage.remove('gtl_prospect_id');
    this.storage.remove('enrollments');
    this.storage.remove('tpa_ids');
    this.storage.remove('policy_number');
    this.storage.remove('userName');
    this.storage.remove('nomineeEdit');
    this.menu.enable(false);
    // this.storage.clear();
    this.modalCtrl.dismiss();
    this.router.navigateByUrl('login');
    // this.toastCtrl.create({
    //   message: 'Something went wrong. Please try again.',
    //   duration: 1000,
    // }).then(t => t.present());
  }
  constructor(protected modalCtrl: ModalController,
              protected params: NavParams,
              protected storage: Storage,
              protected toastCtrl: ToastController,
              protected loadingController: LoadingController,
              protected authenticate: AuthenticationService,
              protected router: Router,
              protected menu: MenuController, ) { }

  ngOnInit() {
    this.baseUrl = environment.apiBaseUrl;
  }
  /**
   *  confirm logout
   */
  async done(){
    this.loadingController.create({
      message: 'Loading...',
      duration: 3000
    }).then((res) => {
      res.present();
    });
    Promise.all([this.storage.get('access_token')]).then(values => {
      console.log(values[0]);
      if (values[0]) {
        this.authenticate.logout(values[0])
            .pipe(
                finalize(() => this.loadingController.dismiss().then((res) => {}).catch((error) => {})),
                first()
            )
            .subscribe(
                this.onLoginSuccess,
                this.onLoginError,
            );
      }
    });
  }
  async cancel() {
    await this.modalCtrl.dismiss();
  }
}
